import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar";
import { Select } from "../components/Core";
import axios from "axios";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import { useFilterSearch } from '../context/FilterSearchContext';
import GlobalContext from "../context/GlobalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';

const defaultCountries = [
    { value: "sp", label: "Singapore" },
    { value: "bd", label: "Bangladesh" },
    { value: "usa", label: "United States of America" },
    { value: "uae", label: "United Arab Emirates" },
    { value: "pk", label: "Pakistan" },
];

const FeaturedJobListingGrid = () => {

    // const { userFilterData = {} } = useFilterSearch() || {};

    const userFilterData = {};

    const [showMore, setShowMore] = useState([]);

    const toggleShowMore = (id) => {
        if (showMore.includes(id)) {
            setShowMore(showMore.filter(i => i !== id));
        } else {
            setShowMore([...showMore, id]);
        }
    };

    const imageBaseUrl = `${network.serverip}/images/`;

    const [search, setSearch] = useState('');
    const [count, setCount] = useState(10);
    const [totalJobCount, setTotalJobCount] = useState(0);
    const [resultsFor, setResultsFor] = useState('');
    const [jobs, setJobs] = useState([]);
    const isMountedRef = useRef(false);
    const gContext = useContext(GlobalContext);
    const { user } = useContext(GlobalContext);

    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (user && user.id) {
            setUserId(user.id)
        }
    }, [user]);

    const handleSearch = async (event) => {
        event.preventDefault();
        setCount(10);
        setShowMore([]);
        await fetchFeaturedJobs([], search, 10);
    };

    const handleLoadMore = async () => {
        try {
            const newCount = count + 10;
            setCount(newCount);
            await fetchFeaturedJobs([], search, newCount);
        } catch (error) {
            console.error('Error loading more jobs:', error);
        }
    };


    const fetchFeaturedJobs = async (userFilterData, search, count) => {
        try {
            const response = await axiosConfig.post('/featuredjoblist-forweb', { userFilterData, search, count, userId });
            if (response.data.success && isMountedRef.current) {
                setJobs(response.data.data);
                setTotalJobCount(response.data.total);
                setResultsFor(search);
            } else if (!response.data.success) {
                if (isMountedRef.current) {
                    setJobs([]);
                    setTotalJobCount(0);
                    setResultsFor(search);
                }
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            if (isMountedRef.current) {
                setJobs([]);
                setTotalJobCount(0);
                setResultsFor(search);
            }
            console.error('Error fetching jobs:', error);
        }
    };

    const handleJobSaveOrUnsave = async (jobId) => {
        try {

            if (!userId) {
                gContext.toggleSignInModal();

                return
            }

            if (userId && jobId) {
                const response = await axiosConfig.post(`/saveOrUnsaveJob-web`, { userId, jobId });

                if (response.data.success && isMountedRef.current) {

                    setJobs((prevState) =>
                        prevState.map((job) =>
                            job.id === jobId
                                ? { ...job, isSaved: job.isSaved === 1 ? 0 : 1 }
                                : job
                        )
                    );

                    console.log('Job saved / unsaved');
                } else {
                    if (isMountedRef.current) {
                        console.log('Failed to save / unsave job');
                    }
                    console.error('Failed to save / unsave job:', response.data.message);
                }
            } else {
                console.error('userId or jobId is missing');
            }
        } catch (error) {
            console.error('Failed to save/ unsave job:', error);
        }
    };

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await fetchFeaturedJobs([], '', 10);
        };

        fetchData();
    }, [userId]);

    const styles = FeaturedJobListingGridStyling();

    // Helper function to split array into chunks
    const chunkArray = (array, chunkSize) => {
        const results = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };

    const jobPairs = chunkArray(jobs, 2);

    return (
        <>
            <PageWrapper>
                <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12 col-md-4 col-xs-8">
                                <Sidebar />
                            </div> */}

                            {/* className="col-12 col-xl-8 col-lg-8" */}
                            <div className="col-12">
                                {/* <!-- form --> */}
                                <form className="search-form" onSubmit={handleSearch}>
                                    <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                                        <div className="filter-inputs">
                                            <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                                                <input
                                                    className="form-control focus-reset pl-13"
                                                    type="text"
                                                    id="search"
                                                    value={search}
                                                    onChange={(event) => setSearch(event.target.value)}
                                                    placeholder="Search"
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            {/* <!-- .select-city starts --> */}
                                            {/* <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                                                <Select
                                                    options={defaultCountries}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                                                    border={false}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div> */}
                                            {/* <!-- ./select-city ends --> */}
                                        </div>
                                        <div className="button-block">
                                            <button
                                                className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                                                type="submit"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="pt-12 ml-lg-0 ml-md-15">
                                    <div className="d-flex align-items-center justify-content-between mb-6">
                                        {resultsFor ? (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalJobCount}</span>
                                                {totalJobCount === 1
                                                    ? ` result for `
                                                    : ` results for `}
                                                <span className="heading-default-color">{resultsFor}</span>
                                            </h5>
                                        ) : (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalJobCount}</span>
                                                {" "} {"Job(s) found"} {" "}
                                            </h5>
                                        )
                                        }
                                        <div className="d-flex align-items-center result-view-type">
                                            <Link
                                                to="/featured-joblisting"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray"
                                            >
                                                <i className="fa fa-list-ul"></i>
                                            </Link>
                                            <Link
                                                to="/featured-joblisting-grid"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                                            >
                                                <i className="fa fa-th-large"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="pt-6">
                                        <div className="row justify-content-start">
                                            {jobPairs.map((pair, rowIndex) => (
                                                <div className="col-12 col-xl-6">
                                                    <div className="row mb-4 justify-content-start" key={rowIndex}>
                                                        {pair.map((job, index) => (
                                                            <div className="col-12 col-lg-6" key={index}>
                                                                <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                                                                    <div className="d-block mb-7">
                                                                        <div style={styles.imageContainer}>
                                                                            <img
                                                                                src={job && job.image ? imageBaseUrl + job.image : ""}
                                                                                alt=""
                                                                                style={styles.jobImage}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <h3 className="font-size-6 heading-default-color">
                                                                            <Link to={`/job-details/?id=${job.id}`}
                                                                                className="font-size-6 heading-default-color d-block mb-0">
                                                                                {job && job.title ? job.title : ''}
                                                                            </Link>
                                                                        </h3>
                                                                        <div className="mt-n4">
                                                                            <p className="font-size-small font-weight-bold text-black-2 mb-4">
                                                                                <span className="mr-2">
                                                                                    <FontAwesomeIcon icon={faHospitalAlt} />
                                                                                </span>
                                                                                {job && job.institution && job.institution.title ? job.institution.title : ''}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                                                                    <li style={styles.jobTypeBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <i className="fa fa-briefcase mr-2"></i>
                                                                            {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                                                        </p>
                                                                    </li>
                                                                    <li style={styles.locationBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <i className="icon icon-pin-3 mr-2"></i>
                                                                            {job && job.location && job.location.title ? job.location.title : ''}
                                                                        </p>
                                                                    </li>
                                                                    <li style={styles.salaryBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <span className="mr-2">₹</span>
                                                                            {job && job.salary ? `${job.salary}/month` : ''}
                                                                        </p>
                                                                    </li>
                                                                </ul> */}
                                                                    <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                                                                        <li style={styles.jobTypeBox}>
                                                                            <div
                                                                                className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                                                                            >
                                                                                <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                                {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                                                            </div>
                                                                        </li>
                                                                        <li style={styles.locationBox}>
                                                                            <div
                                                                                className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                                                            >
                                                                                <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                                {job && job.location && job.location.title ? job.location.title : ''}
                                                                                {job && job.state && job.state.title ? `, ${job.state.title}` : ''}
                                                                            </div>
                                                                        </li>
                                                                        {/* {job?.salary && job.salary > 0 && (
                                                                            <li style={styles.salaryBox}>
                                                                                <div
                                                                                    className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                                                                                >
                                                                                    <span className="mr-2">₹</span>
                                                                                    {job && job.salary ? `${job.salary}/month` : ''}
                                                                                </div>
                                                                            </li>
                                                                        )} */}
                                                                    </ul>
                                                                    <div className="mb-7 font-size-4 text-gray" style={{ textAlign: 'justify' }}>
                                                                        {job && job.description ? (
                                                                            <p>
                                                                                {((showMore.includes(job.id)) || job.description.length <= 150)
                                                                                    ? `${job.description} `
                                                                                    : `${job.description.slice(0, 150)}... `}
                                                                                {job.description.length > 150 && (
                                                                                    // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                                                                                    //     {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                                                                                    // </span>
                                                                                    <Link to={`/job-details/?id=${job.id}`} style={styles.showMoreButton}>
                                                                                        {'Show more'}
                                                                                    </Link>
                                                                                )}
                                                                            </p>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </div>
                                                                    <div className="card-btn-group d-flex justify-content-between">
                                                                        {job?.isApplied === 1 ? (
                                                                            <button className="btn btn-gray text-uppercase btn-medium rounded-3" disabled>
                                                                                Applied
                                                                            </button>
                                                                        ) : (
                                                                            <Link
                                                                                to={`/job-apply/?id=${job?.id}`}
                                                                                className="btn btn-green text-uppercase btn-medium rounded-3"
                                                                            >
                                                                                Apply
                                                                            </Link>
                                                                        )}
                                                                        {/* <Link to="/#" className="btn btn-outline-mercury text-black-2 text-uppercase btn-medium rounded-3">
                                                                            <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i> Save it
                                                                        </Link> */}
                                                                        {job && job.isSaved && job.isSaved === 1 ? (
                                                                            <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                                className="btn btn-outline-mercury text-uppercase h-px-48 rounded-3 mb-5 px-4">
                                                                                <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                                                                                <span className="text-success">Saved</span>
                                                                            </button>
                                                                        ) : (
                                                                            <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                                className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-4">
                                                                                <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                                                                Save job
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="text-center pt-5 pt-lg-13">
                                        {/* <Link
                                            to="/#"
                                            className="text-green font-weight-bold text-uppercase font-size-3 d-flex align-items-center justify-content-center"
                                        >
                                            Load More{" "}
                                            <i className="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                                        </Link> */}
                                        {jobs.length > 0 && totalJobCount > jobs.length ? (
                                            <button onClick={handleLoadMore} style={styles.loadMoreButton}>
                                                <span className="text-green font-weight-bold text-uppercase font-size-3">
                                                    Load More <i className="fas fa-sort-down ml-3"></i>
                                                </span>
                                            </button>
                                        ) : null}

                                        {jobs.length === 0 && (
                                            <span className="heading-default-color font-weight-bold font-size-3">
                                                No jobs found
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/* <!-- form end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default FeaturedJobListingGrid;

function FeaturedJobListingGridStyling() {
    const styles = {
        showMoreButton: {
            display: 'inline-block',
            background: 'none',
            border: 'none',
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        imageContainer: {
            width: '100%',
            aspectRatio: '4 / 3',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
        },
        jobImage: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
        },
        loadMoreButton: {
            border: 'none',
            background: 'none',
        },
        jobTypeBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        locationBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        salaryBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        }
    };

    return styles;
}
